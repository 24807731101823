@import url("https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply w-full mx-auto max-w-[1240px];
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: "Kode Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

.wallet-adapter-button {
  font-family: "Kode Mono", monospace !important;
}
.wallet-adapter-dropdown-list-item {
  font-family: "Kode Mono", monospace !important;
}
